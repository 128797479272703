<script>
import Vue from "vue";
import Layout from "@/router/layouts/main";
import PageHeader from "@/components/page-header";
import Preloader from "@/components/preloader";
import Switches from "vue-switches";
import Multiselect from "vue-multiselect";
import AddressFormGroup from "@/components/form-elements/address-form-group";
import appConfig from "@/app.config";
import {
  required,
  maxLength,
  minLength,
  helpers,
} from "vuelidate/lib/validators";
import { roleService } from "@/app/service/roleService";
import { countryListData } from "@/data/data-country-list";
import Repository from "@/app/repository/repository-factory";

const swiftregex = helpers.regex(
  "swiftregex",
  /([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)/
);

const PaymentBankRepository = Repository.get("PaymentBankRepository");

/**
 * Dodaj bank
 */
export default {
  page: {
    title: "Dodaj bank",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Preloader,
    Multiselect,
    Switches,
    AddressFormGroup,
  },
  data() {
    return {
      header: {
        title: "Dodaj bank",
        items: [
          {
            text: "Strona główna",
            to: {
              name: "home",
            },
          },
          {
            text: "Lista banków",
            to: {
              name: "Lista banków",
            },
          },
          {
            text: "Dodaj bank",
          },
        ],
      },
      preloader: true,
      disabledBtn: false,
      form: {
        name: "",
        shortame: "",
        address: "",
        addressCity: "",
        addressStreet: "",
        addressHouseNo: "",
        addressFlatNo: "",
        addressPostalCode: "",
        addressInternational: "",
        swift: "",
        country: null,
        additionalInfo: "",
        isActive: false,
      },
      dataCountry: countryListData,
    };
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(150),
        minLength: minLength(2),
      },
      shortName: {
        required,
        maxLength: maxLength(20),
      },
      country: {
        required,
      },
      swift: {
        required,
        swiftregex,
        maxLength: maxLength(11),
        minLength: minLength(8),
      },
      additionalInfo: {
        maxLength: maxLength(500),
      },
    },
  },
  created() {
    setTimeout(() => {
      this.preloader = false;
    }, 1000);
  },
  methods: {
    addBank() {
      this.formSubmit();

      if (
        this.$v.$error === true &&
        this.$refs.AddressFormGroup.$v.$invalid === true
      ) {
        return false;
      }

      let payload = Object.assign({}, this.form);
      payload.countryCode = this.form.country.code;

      this.disabledBtn = true;
      this.preloader = true;

      PaymentBankRepository.create(payload)
        .then(() => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "success",
            toast: false,
            position: "top",
            title: "SUKCES!",
            text: "Bank został dodany!",
            showConfirmButton: false,
            timer: 3500,
            onClose: () => {
              this.$router.push({
                name: "Lista banków",
              });
            },
          });
        })
        .catch((error) => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "error",
            position: "top",
            title: "Wystąpił błąd. Spróbuj jeszcze raz!",
            text: error.response.data.message,
            showConfirmButton: true,
          });
        });
    },
    formSubmit(e) {
      this.$v.$touch();
      this.$refs.AddressFormGroup.$v.$touch();
    },
    countryLabel({ name_pl, name_en, code }) {
      return `${name_pl} ${name_en} ${code} `;
    },
    getAddressCityFormValues: function (params) {
      this.form.addressCity = params;
    },
    getAddressStreetFormValues: function (params) {
      this.form.addressStreet = params;
    },
    getAddressHouseNoFormValues: function (params) {
      this.form.addressHouseNo = params;
    },
    getAddressFlatNoFormValues: function (params) {
      this.form.addressFlatNo = params;
    },
    getAddressPostalCodeFormValues: function (params) {
      this.form.addressPostalCode = params;
    },
    getAddressInternationalFormValues: function (params) {
      this.form.addressInternational = params;
    },
  },
  computed: {
    isAdmin() {
      return roleService.isAdmin();
    },
    isProductAdministrator() {
      return roleService.isProductAdministrator();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="header.title" :items="header.items" />
    <Preloader v-if="preloader" />
    <b-form @submit.prevent="formSubmit" v-if="isAdmin || isProductAdministrator">
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow-sm">
            <b-card-header>
              <div class="row align-items-center">
                <div class="col">Formularz dodawania nowego banku</div>
                <div class="col text-right">
                  <b-link class="btn btn-sm btn-warning" :to="{ name: 'Lista banków' }">Anuluj</b-link>
                </div>
              </div>
            </b-card-header>
            <div class="card-body">
              <div class="row justify-content-center">
                <div class="col-md-5">

                  <el-divider>Dane banku</el-divider>

                  <div class="form-group">
                    <label for="name">
                      Nazwa banku
                      <em class="text-danger">*</em>
                    </label>
                    <input id="name" v-model.trim="form.name" @input="$v.form.name.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.name.$error, 'is-valid': !$v.form.name.$invalid && !$v.form.name.$error }" type="text" name="name" placeholder="Wpisz nazwę banku" value required />
                    <div v-if="$v.form.name.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.name.$error && !$v.form.name.required">Pole jest wymagane!</span>
                      <span class="d-block" v-if="$v.form.name.$error && !$v.form.name.maxLength">Przekroczona dozwolona ilość znaków!</span>
                      <span class="d-block" v-if="$v.form.name.$error && !$v.form.name.minLength">Zbyt mała ilość znaków!</span>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="shortName">
                      Skrót banku
                      <em class="text-danger">*</em>
                    </label>
                    <input id="shortName" v-model.trim="form.shortName" @input="$v.form.shortName.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.shortName.$error, 'is-valid': !$v.form.shortName.$invalid && !$v.form.shortName.$error }" type="text" name="shortName" placeholder="Wpisz skrót banku" value required />
                    <div v-if="$v.form.shortName.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.shortName.$error && !$v.form.shortName.required">Pole jest wymagane!</span>
                      <span class="d-block" v-if="$v.form.shortName.$error && !$v.form.shortName.maxLength">Przekroczona dozwolona ilość znaków!</span>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="swift">
                      BIC / SWIFT
                      <em class="text-danger">*</em>
                    </label>
                    <input id="swift" v-model.trim="form.swift" @input="$v.form.swift.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.swift.$error, 'is-valid': !$v.form.swift.$invalid && !$v.form.swift.$error }" type="text" name="swift" placeholder="Wpisz BIC / SWIFT" value required />
                    <div v-if="$v.form.swift.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.swift.$error && !$v.form.swift.required">Pole jest wymagane!</span>
                      <span class="d-block" v-if="$v.form.swift.$error && !$v.form.swift.maxLength">Przekroczona dozwolona ilość znaków!</span>
                      <span class="d-block" v-if="$v.form.swift.$error && !$v.form.swift.minLength">Zbyt mała ilość znaków!</span>
                      <span class="d-block" v-if="$v.form.swift.$error && !$v.form.swift.swiftregex">Wpisano niedozwolone znaki!</span>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="transferAdditionalInfoField">Uwagi</label>
                    <textarea id="transferVatAmountField" v-model.trim="form.additionalInfo" @input="$v.form.additionalInfo.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.additionalInfo.$error, 'is-valid': !$v.form.additionalInfo.$invalid && !$v.form.additionalInfo.$error }" type="text" placeholder="Wpisz dodatkowe uwagi" value></textarea>
                    <div v-if="$v.form.additionalInfo.$invalid" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.additionalInfo.$invalid && !$v.form.additionalInfo.maxLength">Przekroczona dozwolona ilość znaków!</span>
                    </div>
                  </div>

                </div>
                <div class="col-md-5 offset-md-1">

                  <el-divider>Adres banku</el-divider>

                  <div class="form-group">
                    <label for="country">Kraj banku <em class="text-danger">*</em></label>
                    <multiselect id="country" v-model.trim="form.country" :options="dataCountry" :custom-label="countryLabel" :class="{ 'is-invalid': $v.form.country.$error, 'is-valid': !$v.form.country.$invalid && !$v.form.country.$error }" track-by="code" name="country" placeholder="Wybierz kraj banku z listy" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required>
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.name_pl }} / {{ props.option.code }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.name_pl }} / {{ props.option.code }}</span>
                        </div>
                      </template>
                      <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                    </multiselect>
                    <template v-if="$v.form.country.$error">
                      <small class="text-danger">Pole jest wymagane!</small>
                    </template>
                  </div>

                  <!-- <div class="form-group">
                    <label for="address">
                      Adres siedziby banku
                      <em class="text-danger">*</em>
                    </label>
                    <input id="address" v-model.trim="form.address" @input="$v.form.address.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.address.$error, 'is-valid': !$v.form.address.$invalid && !$v.form.address.$error }" type="text" name="address" placeholder="Wpisz adres siedziby banku" value required />
                    <div v-if="$v.form.address.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.address.$error && !$v.form.address.required">Pole jest wymagane!</span>
                      <span class="d-block" v-if="$v.form.address.$error && !$v.form.address.maxLength">Przekroczona dozwolona ilość znaków!</span>
                      <span class="d-block" v-if="$v.form.address.$error && !$v.form.address.minLength">Zbyt mała ilość znaków!</span>
                    </div>
                  </div> -->

                  <AddressFormGroup @address-city-form-group="getAddressCityFormValues" @address-street-form-group="getAddressStreetFormValues" @address-house-no-form-group="getAddressHouseNoFormValues" @address-flat-no-form-group="getAddressFlatNoFormValues" @address-postal-code-form-group="getAddressPostalCodeFormValues" @address-international-form-group="getAddressInternationalFormValues" :incomingAddressCityValue="form.addressCity" :incomingAddressStreetValue="form.addressStreet" :incomingAddressHouseNoValue="form.addressHouseNo" :incomingAddressFlatNoValue="form.addressFlatNo" :incomingAddressPostalCodeValue="form.addressPostalCode" :incomingAddressInternationalValue="form.addressInternational" name="AddressFormGroup" ref="AddressFormGroup" />

                  <el-divider class="mt-5">Status</el-divider>

                  <div class="d-flex justify-content-start">
                    <switches class="mt-2" v-model="form.isActive" type-bold="false" :color="form.isActive == 1 ? 'success' : 'primary'"></switches>
                    <label class="mt-1 ml-3"><span class="mr-2">Aktywny:</span>
                      <span v-if="form.isActive" class="badge badge-pill badge-soft-success font-size-14">TAK</span>
                      <span v-else class="badge badge-pill badge-soft-danger font-size-14">NIE</span>
                    </label>
                  </div>
                  <small id="cardholderNumberHelp" class="form-text text-muted mt-n2"><i class="mdi mdi-information-outline mr-1"></i>Status dostępności banku w systemie</small>
                </div>

                <div class="col-md-12 offset-md-1 mt-3">
                  <button class="btn btn-dark" v-on:click="addBank" :disabled="$v.form.$invalid || this.$refs.AddressFormGroup.$v.$invalid || disabledBtn" type="submit">Dodaj bank</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </Layout>
</template>